import { useState, useEffect } from "react";
import { axiosInstance } from "./AxiosConfiguration";
import { getUserID } from "./AuthBarrier";


export function useFetchVisitResponders(visitID, reload) {
    const [responders, setResponders] = useState(null);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);

    const fetchResponders = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/api/v1/visits/${visitID}/responders`);
            setResponders(response.data);
            setError(null);
        } catch(error) {
            setError(error);
            setResponders(null); 
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchResponders();
    }, [visitID, reload]);

    return { responders, loading, error }
}

export async function tagResponderToVisit(visitID, user_account_id) {
    try {
        const response = await axiosInstance.post(`/api/v1/visits/${visitID}/responders`, {
            "user_account_id": user_account_id,
        })

        return response.status
    } catch(error) {
        console.log(error)
    }
}

export async function removeTaggedResponder(visitID, responder_id) {
    try {
        const response = await axiosInstance.delete(`/api/v1/visits/${visitID}/responders/${responder_id}`)

        return response.status
    } catch(error) {
        console.log(error)
    }
}

export async function getCurrentVisitResponder(visitID) {
    try {
        const response = await axiosInstance.get(`/api/v1/visits/${visitID}/responders`);
        
        for (const responder of response.data) {
            if (responder['user_account_id'] === getUserID()) {
                return responder;
            }
        }

        throw new Error("Couldn't find your responder record");
    } catch(error) {
        console.log(error)
    }
}

export async function approveAllResponders(visitID, responders) {
    try {
        for (const responder of responders) {
            const response = await axiosInstance.put(`/api/v1/visits/${visitID}/responders/${responder['id']}`, {
                "status": 'approved',
                "comment": 'Approved by report creator',
            })
        }

        return true;
    } catch (error) {
        console.log(error);
    }
}