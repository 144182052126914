import { getUserID } from "./AuthBarrier";
import { getVisitWithID } from "./VisitRouter";

export class LocalVisit {
    constructor(id = null, form_title = "", form_top = {}, patient = {}, values = {}) {
        this.id = id;
        this.form_top = form_top;
        this.form_title = form_title;
        this.patient = patient;
        this.values = values;

        this.initialize();
    }

    async initialize() {
        // Check and prep local storage. Should store for EACH SEPERATE USER ID
        const storedId = localStorage.getItem(`${getUserID()}-form-id`);
        const form_top = localStorage.getItem(`${getUserID()}-form-top`);
        const form_title = localStorage.getItem(`${getUserID()}-form-title`);
        const patient = JSON.parse(localStorage.getItem(`${getUserID()}-form-patient`));
        const values = JSON.parse(localStorage.getItem(`${getUserID()}-form-values`));

        // Cross check against what is currently stored in API. If there is a conflict, we'll take the API as the true source of information
        if (storedId && storedId !== "null") {
            this.id = storedId;
        }

        if (form_title && form_title !== "null") {
            this.form_title = form_title;
        }

        if (form_top && form_top !== "null") {
            this.form_top = form_top;   
        } else {
            localStorage.setItem(`${getUserID()}-form-top`, JSON.stringify({}));
        }

        if (patient && patient !== "null") {
            this.patient = patient;
        } else {
            localStorage.setItem(`${getUserID()}-form-patient`, JSON.stringify({}));
        }

        if (values && values !== "null") {
            this.values = values;
        } else {
            localStorage.setItem(`${getUserID()}-form-values`, JSON.stringify({}));
        }

    }

    clearLocalStorage() {
        localStorage.setItem(`${getUserID()}-form-id`, null);
        localStorage.setItem(`${getUserID()}-form-top`, JSON.stringify({}));
        localStorage.setItem(`${getUserID()}-form-title`, "");
        localStorage.setItem(`${getUserID()}-form-patient`, JSON.stringify({}));
        localStorage.setItem(`${getUserID()}-form-values`, JSON.stringify({}));
        
        // Reset the instance variables
        this.id = null;
        this.form_title = null;
        this.form_top = {};
        this.patient = {};
        this.values = {};
    }

    async initializeFromLocalStorage() {
        this.id = localStorage.getItem(`${getUserID()}-form-id`);   

        try {
            if (this.id && this.id !== "null") {
                const response = await getVisitWithID(this.id);
                if (!response || response["status"] !== "draft") {
                    this.clearLocalStorage();
                    return {
                        id: this.id,
                        form_top: this.form_top,
                        title: this.form_title,
                        patient: this.patient,
                        values: this.values,
                    }
                }
                if (response["details"]) {
                    this.form_top = {
                        "date": response["details"]["incident_occurred_at"],
                        "efrt_activation_time": response["details"]["efrt_activated_at"],
                        "ambulance_number": response["details"]["ambulance_number"],
                        "transport": response["details"]["transport"],
                    };
                    localStorage.setItem(`${getUserID()}-form-top`, JSON.stringify(this.form_top));
                } else {
                    this.form_top = JSON.parse(localStorage.getItem(`${getUserID()}-form-top`));
                }

                if (response["patient_info"]) {
                    this.patient = response["patient_info"];
                    localStorage.setItem(`${getUserID()}-form-patient`, JSON.stringify(response["patient_info"]));
                } else {
                    this.patient = JSON.parse(localStorage.getItem(`${getUserID()}-form-patient`));
                }

                if (response["title"]) {
                    this.form_title = response["title"];
                    localStorage.setItem(`${getUserID()}-form-title`, response["title"]);
                } else {
                    this.form_title = localStorage.getItem(`${getUserID()}-form-title`);
                }

                // Free form sections, idk will need to figure out a dynamic way to configure this
                this.values = JSON.parse(localStorage.getItem(`${getUserID()}-form-values`));
            } else {
                this.form_top = JSON.parse(localStorage.getItem(`${getUserID()}-form-top`));
                this.form_title = localStorage.getItem(`${getUserID()}-form-title`);
                this.patient = JSON.parse(localStorage.getItem(`${getUserID()}-form-patient`));
                this.values = JSON.parse(localStorage.getItem(`${getUserID()}-form-values`));
            }
        } catch(error) {
            console.log(error);
        }

        return {
            id: this.id,
            form_top: this.form_top,
            title: this.form_title,
            patient: this.patient,
            values: this.values,
        }
    }

    getID() {
        return this.id;
    }

    getPatient() {
        return this.patient;
    }

    getValues() {
        return JSON.parse(localStorage.getItem(`${getUserID()}-form-values`));
    }

    getFormTop() {
        return this.form_top;
    }

    getFormTitle() {
        return this.form_title;
    }

    setID(newID) {
        this.id = newID;
        localStorage.setItem(`${getUserID()}-form-id`, this.id);
    }

    setTitle(input) {
        this.form_title = input;
        localStorage.setItem(`${getUserID()}-form-title`, this.form_title);
    }

    setPatient(input) {
        if (!input) {
            return;
        }
        this.patient = input;
        localStorage.setItem(`${getUserID()}-form-patient`, JSON.stringify(this.patient))
    }

    addGenResult(key, generated_result) {
        this.values[key] = {
            "generated_result": {
                "value": generated_result
            },
            ...this.values[key],
        }

        localStorage.setItem(`${getUserID()}-form-values`, JSON.stringify(this.values))
    }

    setValues(key, input) {
        // Create key first if it doesn't exist
        if (!input || input === "null" || input === null) {
            return
        }

        try {
            if (!(key in this.values)) {
                this.values[key] = {
                    "user_edit": {
                        "value": {
                            ...input
                        },
                    },
                }
            } else if ("user_edit" in this.values[key]) {
                this.values[key]["user_edit"]["value"] = {
                    ...input
                }
            }
            localStorage.setItem(`${getUserID()}-form-values`, JSON.stringify(this.values))
        } catch (error) {
            console.log(error);
        }
    }

    setFormTop(input) {
        if (!input || input === "null" || input === null) {
            return;
        }
        this.form_top = input;
        localStorage.setItem(`${getUserID()}-form-top`, JSON.stringify(this.form_top))
    }

}