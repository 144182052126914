
import { Button, DialogActions, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog } from "@mui/joy";
import React from "react";


function AudioNotSubmittedModal({open, onClose, submitAudio, continueSaveForm}) {

    return <Modal open={open} onClose={onClose}>
        <ModalDialog variant="outlined">
            <ModalClose />
            <DialogTitle> Audio to start dictation not submitted! </DialogTitle>
            <DialogContent> Your audio has not been uploaded yet. If you start a new form, your audio will be lost! Don't worry, you just have to submit it, then you can leave. It'll be queued and completed in the background! </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    onClose()
                    submitAudio()}}>
                    Submit Audio!
                </Button>
                <Button variant="plain" sx={{backgroundColor: "white"}}onClick={() => {
                    onClose()
                    continueSaveForm()}}>
                    Start new form and lose audio.
                </Button>
            </DialogActions>
        </ModalDialog>
    </Modal>
}

export default AudioNotSubmittedModal;