import axios from "axios";
import axiosRetry from "axios-retry"; // Import axiosRetry
import { clearUser, getToken } from "./AuthBarrier";

// For axios calls with auth required
export const axiosInstance = axios.create({
    baseURL: process.env.NODE_ENV === "production" ? "" : process.env.REACT_APP_HOST,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add request interceptor
axiosInstance.interceptors.request.use((config) => {
    const token = getToken(); // Retrieve the latest token
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`; // Set the Authorization header
    }
    return config;
}, (error) => {
    // Handle request error here
    return Promise.reject(error);
});

// Add response interceptor
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 403) { // Logout based off of invalid token
            clearUser();
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

// Add axiosRetry to the instance
axiosRetry(axiosInstance, {
    retries: 3, // Number of retry attempts
    retryDelay: (retryCount) => {
        console.log(`Retry attempt: ${retryCount}`);
        return retryCount * 20000; // Delay between retries (in ms)
    },
    retryCondition: (error) => {
        // Retry only if the error response status is 503
        return error.response && error.response.status === 503;
    },
});
