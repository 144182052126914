import { Modal, ModalDialog, DialogTitle, DialogContent, Button, FormControl, FormLabel, Input, Typography, ModalClose } from "@mui/joy";
import React, { useContext, useEffect, useState } from "react";

export default function ApproveAllResponders({open, onClose, submit}) {

    return <Modal open={open} onClose={onClose}>
        <ModalDialog>
            <ModalClose />
            <DialogTitle> Approve all tagged responders?</DialogTitle>
            <DialogContent> By using the "Approve All Pending Reviewers" function, the owner agrees to approve all reviewers with a pending status in a single action. The owner acknowledges full responsibility for this approval and confirms that the action is intentional and authorized. </DialogContent>
            <form onSubmit={ async () => {
                await submit();
            }}>
                <Button type="submit" fullWidth sx={{my: 2}}>Approve all!</Button>
            </form>
        </ModalDialog>
    </Modal>
}
