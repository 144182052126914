import { useEffect, useState } from "react";
import { axiosInstance } from "./AxiosConfiguration";

export async function getClientUser() {
    try {
        const response = await axiosInstance.get(`/api/v1/user`)

        return response.data
    } catch(error) {
        console.log(error)
    }
}

export function useFetchOrgUsers(userType = null) {
    const [ usersLoaded, setUsers ] = useState(null);
    const [ loadingUsers, setLoading ] = useState(null);
    const [ errorUsers, setError ] = useState(null);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            let response;
            if (userType) {
                response = await axiosInstance.get(`/api/v1/users`, {
                    params: {
                        user_type: userType
                    }
                })
            } else {
                response = await axiosInstance.get(`/api/v1/users`)
            }
            setUsers(response.data);
            setError(null);
        } catch (error) {
            setError(error)
            setUsers(null); 
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [userType]);


    return { usersLoaded, loadingUsers, errorUsers }
}


export async function getUserByID(user_account_id) {
    try {
        const response = await axiosInstance.get(`/api/v1/user/${user_account_id}`)

        return response.data
    } catch(error) {
        console.log(error);
    }
}

export function userAccountTypePrettier(userType) {
    return userType === "individual" ? "Responder" : 
    userType === "organization_admin" ? "Executive" :
    userType === "admin" ? "Admin" : 
    "User"
}

export function userAccountTypeChipColor(userType) {
    return userType === "individual" ? "var(--main-blue)" : 
    userType === "organization_admin" ? "var(--dark-blue-button)" :
    userType === "admin" ? "var(--light-green)" : 
    "var(--main-blue)"
}