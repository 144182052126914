import { Modal, ModalDialog, DialogTitle, DialogContent, Button, FormControl, FormLabel, Input, Typography } from "@mui/joy";
import React, { useContext, useEffect, useState } from "react";
import { axiosInstance } from "../helpers/AxiosConfiguration";
import { AlertContext } from "./AlertContext";
import { getCurrentVisitResponder } from "../helpers/VisitResponder";
import { getCurrentVisitExecutive } from "../helpers/VisitExecutives";


const WARNING = ["approve", "deny"];

function PublishingModal({open, formAction, setClose, visitID}) {
    const { addAlert } = useContext(AlertContext);
    const [comment, setComment] = useState("");
    const [title, setTitle] = useState("");


    useEffect(() => {
        if (formAction === 'review') {
            setTitle("Send for review?");
        } else if (formAction === 'deny') {
            setTitle("Send back for changes?");
        } else if (formAction === 'approve') {
            setTitle("Approve incident report?")
        }
    }, [formAction])



    return <Modal open={open} onClose={() => setClose()}>
        <ModalDialog>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent> Attach any additional message alongside your review.</DialogContent>
            <form onSubmit={async (event) => {
                event.preventDefault();
                switch(formAction) {
                    case 'draft': {
                        await axiosInstance.post(`/api/v1/visits/${visitID}/statuses`, {
                            "value": 'draft',
                            "comment": comment,
                        }).then( response => {
                            addAlert("Report moved back to draft!", "success");
                            setClose();
                        }).catch(error => {
                            addAlert("Unable to move to draft.", "danger");
                            setClose();
                        })
                        break;
                    }
                    case 'prelim_check': {
                        await axiosInstance.post(`/api/v1/visits/${visitID}/statuses`, {
                            "value": 'prelim_check',
                            "comment": comment,
                        }).then( response => {
                            addAlert("Report sent for preliminary check!", "success");
                            setClose();
                        }).catch(error => {
                            addAlert("Unable to send for preliminary check.", "danger");
                            setClose();
                        });
                        break;
                    }
                    case 'prelim_check_deny': {
                        const visit_responder = await getCurrentVisitResponder(visitID);

                        await axiosInstance.put(`/api/v1/visits/${visitID}/responders/${visit_responder["id"]}`, {
                            "status": 'pending',
                            "comment": comment,
                        }).then( response => {
                            addAlert("Comment posted!", "success");
                            setClose();
                        }).catch(error => {
                            addAlert("Unable to post comment", "danger");
                            setClose();
                        });
                        break;
                    }
                    case 'prelim_check_approve': {
                        const visit_responder = await getCurrentVisitResponder(visitID);

                        await axiosInstance.put(`/api/v1/visits/${visitID}/responders/${visit_responder["id"]}`, {
                            "status": 'approved',
                            "comment": comment,
                        }).then( response => {
                            addAlert("Approved!", "success");
                            setClose();
                        }).catch(error => {
                            addAlert("Unable to approve", "danger");
                            setClose();
                        });
                        break;
                    }
                    case 'review' : {
                        await axiosInstance.post(`/api/v1/visits/${visitID}/statuses`, {
                            "value": 'review',
                            "comment": comment,
                        }).then( response => {
                            addAlert("Report sent for review!", "success");
                            setClose();
                        }).catch(error => {
                            addAlert("Unable to send for review.", "danger");
                            setClose();
                        });

                        break;  // Add break to prevent fall-through
                    }
                    case 'deny': {
                        const visit_exec = await getCurrentVisitExecutive(visitID);

                        await axiosInstance.put(`/api/v1/visits/${visitID}/executives/${visit_exec['id']}`, {
                            "status": 'denied',
                            "comment": comment,
                        }).then( response => {
                            addAlert("Report approval denied and sent back for editing!", "success");
                        }).catch(error => {
                            console.log(error)
                            addAlert("Unable to deny.", "danger");
                        }).finally(() => {
                            setClose();
                        })

                        break;  // Add break to prevent fall-through
                    }
                    case 'approve': {
                        const visit_exec = await getCurrentVisitExecutive(visitID);

                        await axiosInstance.put(`/api/v1/visits/${visitID}/executives/${visit_exec['id']}`, {
                            "status": 'approved',
                            "comment": comment,
                        }).then( response => {
                            addAlert("Report approved!", "success");
                        }).catch(error => {
                            console.log(error)
                            addAlert("Unable to approve.", "danger");
                        }).finally(() => {
                            setClose();
                        });
                        break;  // Add break to prevent fall-through
                    }
                    case 'approve_ammendment': {
                        const visit_exec = await getCurrentVisitExecutive(visitID);

                        await axiosInstance.put(`/api/v1/visits/${visitID}/executives/${visit_exec['id']}`, {
                            "status": 'approved',
                            "comment": comment,
                        }).then( response => {
                            addAlert("Ammendment added!", "success");
                        }).catch(error => {
                            console.log(error)
                            addAlert("Unable to add ammendment", "danger");
                        }).finally(() => {
                            setClose();
                        });
                        break;  // Add break to prevent fall-through
                    }
                }
            }}>
                <FormControl>
                    <FormLabel> Comment: </FormLabel>
                    {formAction in WARNING && <Typography> 
                        **Please note that any approval or denial will immediately take effect for this report.
                        If multiple executives are tagged, please ensure that they are aware of your approval or denial. **
                        </Typography>}
                    <Input required value={comment} onChange={(event) => {
                        setComment(event.target.value);
                    }}/>
                </FormControl>

                <Button type="submit" fullWidth sx={{my: 2}}>Send</Button>
            </form>
        </ModalDialog>
    </Modal>
}


export default PublishingModal