import React, { useEffect, useState } from "react";
import Intercom from '@intercom/messenger-js-sdk';
import { userAccountTypeChipColor, userAccountTypePrettier } from "../helpers/UserAccounts";
import { Box, Typography, Card, Chip } from "@mui/joy";

function Component({ user_id, user_name, user_email, user_created_at }) {
    useEffect(() => {
        // Initialize Intercom only once on mount
        Intercom({
            app_id: 'x7wcjiar',
            user_id: user_id,
            name: user_name,
            email: user_email,
            created_at: user_created_at,
        });

        // Cleanup function if Intercom has a close or reset option
        return () => {
            if (Intercom.shutdown) {
                Intercom.shutdown(); // Example cleanup if supported
            }
        };
    }, [user_id, user_name, user_email, user_created_at]);

    return null; // No content to display
}

export default function GetHelp({user}) {

    return (
        <>
            <Box sx={{ height: "100%", alignContent: "end", mb: "85px", mx: "10px"}}> {/* Example styling */}
                <Card color="primary">
                    <Typography level="body-lg"> Welcome back <Typography level="h4" sx={{mx: 0.2}}>{user['full_name']}</Typography>!</Typography>
                    <Chip variant="solid" size="lg" sx={{ backgroundColor: (userAccountTypeChipColor(user['type'])) }}>{userAccountTypePrettier(user['type'])}</Chip>
                </Card>
                <Component
                    user_id={user['id']}
                    user_name={user['full_name']}
                    user_email={user['email']}
                    user_created_at={user['created_at']}
                />
            </Box>
        </>
    );
}
