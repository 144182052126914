import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/joy';
import { styled } from '@mui/system';

const DateStyledInput = styled('input')(({ theme }) => ({
  border: `1px solid ${theme.palette.neutral.outlinedBorder}`,
  borderRadius: theme.radius.sm,
  minWidth: 0,
  width: '25px',
  height: '20px',
  padding: '10px',
  textAlign: 'center',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontStyle: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  '&::placeholder': {
    opacity: 0.5,
    transition: '0.1s ease-out',
  },
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary.outlinedHoverBorder,
    boxShadow: `0 0 0 2px ${theme.palette.primary.outlinedHoverBorder}`,
  },
  '&:-webkit-autofill': {
    boxShadow: '0 0 0 1000px white inset',
  },
}));

const DateInput = ({ label, labelMinWidth, onChange, value, disabled }) => {
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');

  useEffect(() => {
    if (value) {
      let date;
      if (value.includes('T')) {
        date = value.split('T')[0]
      } else {
        date = value.split(' ')[0]
      }
      const [y, m, d] = date.split('-');
      setYear(y || '');
      setMonth(m || '');
      setDay(d || '');
    }
  }, [value]);

  const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const adjustDate = (y, m, d) => {
    let adjustedYear = Math.max(1, parseInt(y) || 0);
    let adjustedMonth = Math.min(12, Math.max(1, parseInt(m) || 0));
    let maxDays = getDaysInMonth(adjustedYear, adjustedMonth);
    let adjustedDay = Math.min(maxDays, Math.max(1, parseInt(d) || 0));

    return [
      adjustedYear.toString().padStart(4, '0'),
      adjustedMonth.toString().padStart(2, '0'),
      adjustedDay.toString().padStart(2, '0')
    ];
  };

  const handleInputChange = (setter, index) => (e) => {
    const newValue = e.target.value.replace(/\D/g, '');
    const maxLength = index === 0 ? 4 : 2;
    setter(newValue.slice(0, maxLength));
    
    let updatedValues = [year, month, day];
    updatedValues[index] = newValue.slice(0, maxLength);
    
    if (updatedValues[0].length === 4 && updatedValues[1].length === 2 && updatedValues[2].length === 2) {
      const [adjustedYear, adjustedMonth, adjustedDay] = adjustDate(...updatedValues);
      
      setYear(adjustedYear);
      setMonth(adjustedMonth);
      setDay(adjustedDay);

      const formattedDate = [adjustedYear, adjustedMonth, adjustedDay].join('-');
      onChange(formattedDate);
    }
  };

  return (
    <Box display='flex'>
      <Typography sx={{ minWidth: labelMinWidth, alignSelf: "center" }}>
          {label}: 
      </Typography>
      <Box
        pl='10px'
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          my: 1,
        }}
      >
        <DateStyledInput
          placeholder="YYYY"
          disabled={disabled}
          value={year}
          onChange={handleInputChange(setYear, 0)}
          maxLength={4}
          sx={{width:'50px'}}
        />
        <DateStyledInput
          placeholder="MM"
          value={month}
          disabled={disabled}
          onChange={handleInputChange(setMonth, 1)}
          maxLength={2}
        />
        <DateStyledInput
          placeholder="DD"
          disabled={disabled}
          value={day}
          onChange={handleInputChange(setDay, 2)}
          maxLength={2}
        />
      </Box>
    </Box>
  );
};

export default DateInput;