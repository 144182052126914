import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Typography, Button, IconButton, Stack, Divider, Skeleton, CircularProgress, Tooltip } from '@mui/joy';
import { ArrowBack, FileDownload, Delete, Edit, PersonOutlineOutlined } from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { axiosInstance } from '../helpers/AxiosConfiguration';
import { retrieveDateStringFromISO, retrieveTimeStringFromISO } from '../helpers/TimeConversionHelper';
import AutoBuildFormSection from './AutomaticFormBuilder';
import RenderDifferentIcons from './AccordionIcons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DocumentGenerator from '../helpers/DownloadPDF';

import { AlertContext } from './AlertContext';
import { useNavigate, useParams } from 'react-router-dom';
import PublishingModal from './PublishingModal';
import FeedbackCard from './FeedbackComponent';
import StatusesModal from './StatusesModal';
import { getVisitWithID } from '../helpers/VisitRouter';
import { giveBackUserViewVisit } from '../helpers/DecideBetweenUserEditOrGenerated';
import { getUserID } from '../helpers/AuthBarrier';
import UploadAdditionalDocsModal from './modals/UploadFilesModal';
import { AttachUsersToVisit } from './AttachUsers';

const patientFormFields = {
    "field_order": ["first_name", "last_name", "sex", "date_of_birth", "address", "id_number"],
    "first_name": {
        "type": "string",
        "required": true,
    },
    "last_name": {
        "type": "string",
        "required": true,
    },
    "sex": {
        "type": "toggle",
        "values": ["male", "female", "other"]
    },
    "date_of_birth": {
        "type": "datetime",
        "display": "datetime_date",
        "required": true,
    },
    "address": {
        "type": "string",
        "required": true,
    },
    "id_number": {
        "type": "string",
        "required": true,
    },
};



function PublishWorkflowOptions({ userRole, formState, triggerModal, creator, reviewAllowed, noTagged, isExecTagged }) {
    let content;
    
    switch(userRole) {
        case 'individual': {
            switch(formState) {
                case 'draft': {
                    content = (
                        <Tooltip title="Please ensure you've tagged at least one response and one executive">
                            <Button onClick={() => {
                                triggerModal("prelim_check")
                                
                            }} disabled={noTagged}>
                                Submit for Preliminary Check
                            </Button>
                        </Tooltip>
                    );
                    break;
                }
                case 'prelim_check': {
                    {/* Check if this is the creator if the visit. If it is the creator, then check the status' of all of the responders. */}
                    if (creator) {
                        content = (
                            <Box sx={{display: "flex", gap: 1}}>
                                <Button variant="plain" sx={{color: "var(--dark-yellow)", border: "1px var(--dark-yellow) solid", backgroundColor: "white"}}
                                onClick={() => {
                                    triggerModal("draft")
                                }}>
                                    Return to Draft
                                </Button>
                                <Button variant="plain" sx={{color: "green", border: "1px green solid", backgroundColor: "white"}}
                                onClick={() => {
                                    triggerModal("review")
                                }}
                                disabled={!reviewAllowed}
                                >
                                    {reviewAllowed ? "Send for review" : "Awaiting check from tagged users"}
                                </Button>
                            </Box>
                        )
                    } else {
                        content = (
                            <Box sx={{display: "flex", gap: 1}}>
                                <Button variant="plain" sx={{color: "var(--dark-yellow)", border: "1px var(--dark-yellow) solid", backgroundColor: "white"}}
                                onClick={() => {
                                    triggerModal("prelim_check_deny")
                                }}>
                                    Request changes
                                </Button>
                                <Button variant="plain" sx={{color: "green", border: "1px green solid", backgroundColor: "white"}}
                                    onClick={() => {
                                        triggerModal("prelim_check_approve")
                                    }}
                                >
                                    Approve
                                </Button>
                            </Box>
                        )
                    }
                    break;
                }
                case 'review': {
                    content = (
                        <Box sx={{display: "flex", gap: 1}}>
                            {creator && <Button onClick={() => {
                                    triggerModal("draft")
                                }} variant="plain" sx={{color: "var(--dark-yellow)", border: "1px var(--dark-yellow) solid", backgroundColor: "white"}}>
                                    Return to Draft
                            </Button>}
                            <Button disabled>
                                Currently under review
                            </Button>
                        </Box>
                    );
                    break;
                }
                case 'approved': {
                    content = (
                        <Button disabled>
                            Approved and locked!
                        </Button>
                    );
                    break;
                }
                case 'denied': {
                    if (creator) {
                        content = (
                            <Button onClick={() => {
                                triggerModal("draft")
                            }} variant="plain" sx={{color: "var(--dark-yellow)", border: "1px var(--dark-yellow) solid", backgroundColor: "white"}}>
                                Return to Draft
                            </Button>
                        )
                    }
                    else {
                        content = (
                            <Button disabled>
                                This draft has been denied approval and is pending return to draft.
                            </Button>
                        );
                    }
                    break;
                }
                default: {
                    content = null;
                }
            }
            break;
        }
        case 'organization_admin': {
            switch(formState) {
                case 'draft': {
                    content = (
                        <Button onClick={() => {
                            triggerModal("prelim_check")
                        }}>
                            Submit for Preliminary Check
                        </Button>
                    );
                    break;
                }
                case 'prelim_check': {
                    {/* Check if this is the creator if the visit. If it is the creator, then check the status' of all of the responders. */}
                    if (creator) {
                        content = (
                            <Box sx={{display: "flex", gap: 1}}>
                                <Button variant="plain" sx={{color: "var(--dark-yellow)", border: "1px var(--dark-yellow) solid", backgroundColor: "white"}}
                                onClick={() => {
                                    triggerModal("draft")
                                }}>
                                    Return to Draft
                                </Button>
                                <Button variant="plain" sx={{color: "green", border: "1px green solid", backgroundColor: "white"}}
                                onClick={() => {
                                    triggerModal("review")
                                }}
                                disabled={!reviewAllowed}
                                >
                                    {reviewAllowed ? "Send for review" : "Awaiting check from tagged users"}
                                </Button>
                            </Box>
                        )
                    }
                    break;
                }
                case 'review': {
                    content = (
                        <Box sx={{display: "flex", gap: 1}}>
                            <Button variant="plain" sx={{color: "red", border: "1px red solid", backgroundColor: "white"}}
                            onClick={() => {
                                triggerModal("deny")
                            }} >
                                Deny and request changes
                            </Button>
                            <Button variant="plain" sx={{color: "green", border: "1px green solid", backgroundColor: "white"}}
                            onClick={() => {
                                triggerModal("approve")
                            }}>
                                Approve
                            </Button>
                        </Box>
                    );
                    break;
                }
                case 'approved': {
                    if (isExecTagged) {
                        content = (
                            <Button variant="plain" sx={{color: "var(--dark-yellow)", border: "1px var(--dark-yellow) solid", backgroundColor: "white"}}
                                onClick={() => {
                                    triggerModal("approve_ammendment")
                                }}>
                                Add Ammendment
                            </Button>
                        );
                    } else {
                        content = (
                            <Button disabled>
                                Approved and locked!
                            </Button>
                        );
                    }
                    break;
                }
                case 'deny': {
                    content = (
                        <Button disabled>
                            This draft has been denied approval and is pending return to draft.
                        </Button>
                    );
                    break;
                }
                default: {
                    content = null;
                }
            }
            break;
        }
        default: {
            switch(formState) {
                case 'draft': {
                    content = (
                        <Button onClick={() => {
                            triggerModal("review")
                        }}>
                            Submit for Review
                        </Button>
                    );
                    break;
                }
                case 'review': {
                    content = (
                        <Box sx={{display: "flex", gap: 1}}>
                            <Button variant="plain" sx={{backgroundColor: "white", color: "red", border: "1px red solid"}} onClick={() => {
                                triggerModal("deny")
                            }}>
                                Deny and request changes
                            </Button>
                            <Button variant="plain" sx={{backgroundColor: "white", color: "green", border: "1px green solid"}} onClick={() => {
                                triggerModal("approve")
                            }}>
                                Approve!
                            </Button>
                        </Box>
                    );
                    break;
                }
                case 'approved': {
                    content = (
                        <Button disabled>
                            Approved and locked!
                        </Button>
                    );
                    break;
                }
                case 'deny': {
                    content = (
                        <Button disabled>
                            This draft has been denied approval and is pending return to draft.
                        </Button>
                    );
                    break;
                }
                default: {
                    content = null;
                }
            }
        }
    }

    return (
        <Box sx={{display: "flex"}}>
            {content}
        </Box>
    );
}


const PastReportsExpanded = () => {
    const { addAlert } = useContext(AlertContext);
    const navigator = useNavigate();
    const { visitID } = useParams();
    const [visit, setVisit] = useState(null);
    const [orgLogo, setOrgLogo] = useState(null);
    const [pdfLogo, setPDFLogo] = useState(null);
    const [vitalsFormFields, setVitalsFormFields] = useState(null);
    const [allFormField, setFormFields] = useState(null);
    const [userType, setUserType] = useState(null);

    const [openModal, setOpenModal] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [publishStep, setPublishStep] = useState(null);
    const [ openAttachmentsModal, setAttachmentsModal ] = useState(false);

    const [statuses, setStatuses] = useState(null);

    const [ reviewAllowed, setReviewAllowed ] = useState(false);
    const [ noTagged, setNoTagged ] = useState(false);
    const [ isExecTagged, setIsExecTagged ] = useState(false);

    const pdfVisit = useRef({});

    async function convertS3ImageToBase64(imageUrl) {
        try {
          const response = await fetch(imageUrl, {
            method: "GET"
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          const blob = await response.blob();
          const reader = new FileReader();
      
          return new Promise((resolve, reject) => {
            reader.onloadend = () => {
              resolve(reader.result); // This is the base64 string
            };
            reader.onerror = reject;
      
            reader.readAsDataURL(blob);
          });
        } catch (error) {
          console.error('Error fetching or converting image:', error);
          throw error; // Rethrow so the caller knows an error occurred
        }
      }

    async function getOrganizationDetails() {
        try {
            const [detailsResponse, visitResponse] = await Promise.all([
                axiosInstance.get(`/api/v1/organization/details`),
                getVisitWithID(visitID),
            ]);
            
            setVisit(visitResponse);
            const combineVisitResponse = giveBackUserViewVisit(visitResponse);
            pdfVisit.current = { 
                ...visitResponse,
                ...combineVisitResponse,
            }; 
            pdfVisit.current.details = {
                "date": retrieveDateStringFromISO(visitResponse.details.incident_occurred_at),
                "efrt_activation_time": retrieveTimeStringFromISO(visitResponse.details.efrt_activated_at),
                "ambulance_number": visitResponse.details.ambulance_number,
                "transport": visitResponse.details.transport,
            }
            setOrgLogo(detailsResponse.data.settings.logo_url);
            setVitalsFormFields(detailsResponse.data.form_fields.vitals_info);
            setFormFields(detailsResponse.data.form_fields);


            convertS3ImageToBase64(detailsResponse.data.settings.logo_url).then(base64 => {
                setPDFLogo(base64);
            }).catch(error => {
                console.log(error)
            });

        } catch (error) {
            console.log("Error fetching organization data:", error);
        }
    }

    async function getUserRole() {
        try {
            const response = await axiosInstance.get('/api/v1/user');
            setUserType(response.data.type);
        } catch(error) {
            console.log(error);
        }
    }

    async function getReportStatus() {
        try {
            // Fetch status, don't display if "total" === 0
            const response = await axiosInstance.get(`/api/v1/visits/${visitID}/statuses`);
            if (response.data.total > 0) {
                setStatuses(response.data.items);
            }
        } catch(error) {
            console.log(error);
            addAlert("Couldn't fetch most recent status", "danger");
        }
    }

    useEffect(() => {
        getUserRole();
        getReportStatus();
        getOrganizationDetails();
    }, []);


    const deleteVisit = async () => {
        await axiosInstance.delete(`/api/v1/visits/${visit.id}`).then(response => {
            addAlert("Successfully deleted!", "success")
        }).catch(error => {
            addAlert("Couldn't delete report.", "danger")
        })
        navigator('/past-reports')
    }

    const triggerModal = (action) => {
        setPublishStep(action);
        setOpenModal(true);
    }

    const closeStatusModal = (action) => {
        setStatusModal(action);
    }

	return (
        <Box>
            {visit && <UploadAdditionalDocsModal open={openAttachmentsModal} setClose={() => { setAttachmentsModal(false) }} visitID={visit.id} visit_status={visit.status}/>}
            {visit && <PublishingModal open={openModal} formAction={publishStep} setClose={() => {
                setOpenModal(false);
                getReportStatus();
                getOrganizationDetails();
                }} visitID={visit.id} />}
            {statuses && <StatusesModal open={statusModal} setClose={() => {closeStatusModal(false)}} statuses={statuses}/>}
            {visit ? <Box pl={5} pr={5} sx={{overflowY: "scroll", height: (statuses ? "97vh" : "99vh")}}>
                <Divider />
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2, mt: 2 }}>
                    <Stack direction='row' justifyContent='space-between'>
                        <Box>
                            <IconButton onClick={() => {navigator(-1)}} sx={{ mr: 2 }}>
                                <ArrowBack />
                            </IconButton>
                        </Box>
                        <Stack direction='row' >
                            {allFormField && <Button 
                                startDecorator={<FileDownload />} 
                                sx={{ 
                                    display: 'flex',
                                    aligntItems: 'center',
                                    mr: 1,
                                    bgcolor: 'transparent',
                                    color: 'black',
                                    outline: '1px solid gray',
                                }}
                            >
                                <PDFDownloadLink
                                    document={<DocumentGenerator organizationPNG={pdfLogo} formFields={allFormField} visitResult={ {...pdfVisit.current}}/>}
                                    fileName={(visit.title !== null && visit.title !== '' && visit.title !== 'null') ? visit.title : `Report ${new Date(visit.details.incident_occurred_at).toLocaleDateString()}, ${new Date(visit.details.efrt_activated_at).toLocaleTimeString()}`}>
                                    {({ allFormField }) => (allFormField ? 'Generating PDF...' : 'Download PDF')}
                                </PDFDownloadLink>
                            </Button>}
                            <Button 
                                startDecorator={<Delete/>} 
                                onClick={() => deleteVisit()}
                                sx={{ 
                                    display: 'flex',
                                    aligntItems: 'center',
                                    bgcolor: 'transparent',
                                    color: 'black',
                                    outline: '1px solid gray',
                                }}
                            >
                                Delete
                            </Button>
                        </Stack>
                    </Stack>
                    <Typography level='h1' pl={7} visibility='hidden'>{visit.title || 'No Title'}</Typography>
                    <Stack direction='row' justifyContent='space-between' pl={7}>
                        <Typography level='h2'>
                            {(visit.title !== null && visit.title !== '' && visit.title !== 'null') ? visit.title : `Report ${new Date(visit.details.incident_occurred_at).toLocaleDateString()}, ${new Date(visit.details.efrt_activated_at).toLocaleTimeString()}`}
                        </Typography>
                        <Typography level='body-lg'>
                            {new Date(visit.created_at).toLocaleString()}
                        </Typography>
                    </Stack>
                </Box>
                <Divider />
                {statuses && <FeedbackCard status={statuses[0].value} comment={statuses[0].comment} time={statuses[0].created_at}/>}
                <Box pt={1}>
                    <Box display='flex' flexDirection='row-reverse' gap={1}>
                        {/* PUT THE PUBLISH/WORKFLOW TRIGGER STUFF HERE */}
                        {userType && <PublishWorkflowOptions userRole={userType} formState={visit.status} triggerModal={triggerModal} creator={visit['user_account_id'] === getUserID()} reviewAllowed={reviewAllowed} noTagged={noTagged} isExecTagged={isExecTagged}/>}
                        {statuses && <Button 
                            onClick={() => {
                                setStatusModal(true);
                            }}
                        >
                            View Report Statuses
                        </Button>}
                        <Button 
                            startDecorator={<Edit />} 
                            disabled={!( (visit.status === "draft" || visit.status === "denied" ) && (visit.user_account_id === getUserID()))}
                            onClick={() => {
                                navigator(`/past-reports/editing/${visit.id}`)
                            }}
                            sx={{ 
                                display: 'flex',
                                aligntItems: 'center',
                                bgcolor: 'transparent',
                                color: 'black',
                                outline: '1px solid gray',
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            startDecorator={<FileUploadIcon />}
                            sx={{
                                display: "flex",
                                color: "white",
                                backgroundColor: "var(--dark-blue-button)",
                                '&:hover': {
                                    backgroundColor: "var(--dark-blue-button-hover)",
                                },
                            }}
                            onClick={() => {
                                setAttachmentsModal(true);
                            }}
                        >
                            Attachments
                        </Button>
                    </Box>
                    <Stack direction='row' justifyContent='space-evenly' alignItems='center'>
                        <Box>
                        {orgLogo && (
                            <img 
                                src={orgLogo} 
                                style={{ maxHeight: '150px', height: 'auto' }}
                            />
                        )}
                        </Box>
                        <Stack direction='column'>
                            <Typography>Date: {retrieveDateStringFromISO(visit.details["incident_occurred_at"])}</Typography>
                            <Typography>Efrt Activation Time: {retrieveTimeStringFromISO(visit.details["efrt_activated_at"])}</Typography>
                            <Typography>Ambulance Number: {visit.details["ambulance_number"]}</Typography>
                            <Typography>Transport: {visit.details["transport"]}</Typography>
                        </Stack>
                    </Stack>
                </Box>

                {/* Patient Information Section */}
                {visit.status === "approved" ? (userType === 'organization_admin' || userType === 'admin') && 
                <Box>
                        <Box mt={3} pl={7} pr={7}>
                        <Typography
                            level='h3'
                            startDecorator={<PersonOutlineOutlined />}
                            mb={1}
                        >
                            Patient Information
                        </Typography>
                    </Box>
                    <Divider />
                    <Box mt={3} pl={7} pr={7}>
                        {visit && allFormField && <AutoBuildFormSection formFields={patientFormFields} retrieveInput={() => {}} disabled={true} initialInput={visit['patient_info']}/>}
                    </Box>
                </Box>
                : <Box>
                <Box mt={3} pl={7} pr={7}>
                    <Typography
                        level='h3'
                        startDecorator={<PersonOutlineOutlined />}
                        mb={1}
                    >
                        Patient Information
                    </Typography>
                </Box>
                <Divider />
                <Box mt={3} pl={7} pr={7}>
                    {visit && allFormField && <AutoBuildFormSection formFields={patientFormFields} retrieveInput={() => {}} disabled={true} initialInput={visit['patient_info']}/>}
                </Box>
                </Box>}

                {/* Call Information Section */}
                <Box mt={3} pl={7} pr={7}>
                    <Typography
                        level='h3'
                        startDecorator={<RenderDifferentIcons label={"call_information"}/>}
                        mb={1}
                    >
                        Call Information
                    </Typography>
                </Box>
                <Divider />
                <Box mt={3} pl={7} pr={7}>
                    {visit && allFormField && <AutoBuildFormSection formFields={allFormField['call_info']} retrieveInput={() => {}} disabled={true} initialInput={visit['call_info']}/>}
                </Box>

                {/* Treatment Information Section */}
                <Box mt={3} pl={7} pr={7}>
                    <Typography
                        level='h3'
                        startDecorator={<RenderDifferentIcons label={"treatment_information"} />}
                        mb={1}
                    >
                        Treatment Information
                    </Typography>
                </Box>
                <Divider />
                <Box mt={3} pl={7} pr={7}>
                    {visit && allFormField && <AutoBuildFormSection formFields={allFormField['treatment_info']} retrieveInput={() => {}} disabled={true} initialInput={visit['treatment_info']}/>}
                </Box>

                {/* Vitals Information Section */}
                <Box mt={3} pl={7} pr={7}>
                    <Typography
                        level='h3'
                        startDecorator={<RenderDifferentIcons label={"vitals_information"} />}
                        mb={1}
                    >
                        Vitals Information
                    </Typography>
                </Box>
                <Divider />
                <Box mt={3} pl={7} pr={7}>
                    {vitalsFormFields && (
                        <AutoBuildFormSection
                            formFields={vitalsFormFields}
                            initialInput={visit['vitals_info']}
                            retrieveInput={() => {}} // This is a read-only view
                            dirty={false}
                            disabled={true}
                            setDirty={() => {}} // We don't need to track changes in this view
                            triggerSave={false} // This will disable the inputs
                        />
                    )}
                </Box>

                <AttachUsersToVisit visitID={visitID} creator_id={visit['user_account_id']} visit_status={statuses ? statuses[0].value : visit.status} setAllowedReview={setReviewAllowed} statuses={statuses} setNoTagged={setNoTagged} setIsExecTagged={setIsExecTagged}/>
            </Box> : <Skeleton />}
        </Box>
    );
};

export default PastReportsExpanded;